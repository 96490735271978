import React, { useState, useEffect } from 'react';
import { Globe, Archive, Video, Search, PieChart, Link2, Moon, Sun, Menu, X, GitBranch, Shield, Map, Wifi, Cpu, ChevronRight, BarChart, UserCircle, Eye, EyeOff } from 'lucide-react';
import logoLight from './images/logo-light.png';
import logoDark from './images/logo-dark.png';
import loginLogoLight from './images/login-logo-light.png';
import loginLogoDark from './images/login-logo-dark.png';

// Simple Card component
const Card = ({ children, className, ...props }) => (
  <div className={`bg-white dark:bg-[#102B4E] rounded-lg shadow-md ${className}`} {...props}>
    {children}
  </div>
);

// Define the DashboardCard component
const DashboardCard = ({ title, description, icon: Icon, onClick, link, index }) => (
  <Card
    onClick={index < 4 ? onClick : null}
    className={`transition-all duration-300 hover:shadow-lg hover:-translate-y-1 ${index >= 4 ? 'opacity-50 pointer-events-none' : 'cursor-pointer'}`}
  >
    <div className="p-6 flex flex-col items-center justify-center">
      <Icon size={40} className="text-primary dark:text-yellow-500" />
      <h3 className="mt-4 text-xl font-semibold text-primary dark:text-yellow-500">{title}</h3>
      <p className="mt-2 text-sm text-gray-600 dark:text-yellow-400 text-center">{description}</p>
      <a href={link} target="_blank" rel="noopener noreferrer" className="mt-4 text-primary dark:text-yellow-500">
        <ChevronRight size={20} />
      </a>
    </div>
  </Card>
);

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
      <div className="bg-white dark:bg-[#102B4E] rounded-lg shadow-md w-full max-w-md mx-auto">
        <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-2xl font-bold text-primary dark:text-yellow-500">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500">
            <X size={24} />
          </button>
        </div>
        <div className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

const Login = ({ onClose, darkMode }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'Arabees123@') {
      onClose();
    } else {
      setError('Incorrect password');
    }
  };

    return (
      <div className="flex flex-col items-center">
        <img 
          src={darkMode ? loginLogoDark : loginLogoLight} 
          alt="Login Logo" 
          className="w-48 h-48 mb-6" // Increased size
        />
        <form onSubmit={handleSubmit} className="space-y-4 w-full">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                placeholder="Enter password"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <button type="submit" className="w-full bg-primary text-white rounded-md py-2 hover:bg-primary-dark transition duration-300">Login</button>
        </form>
      </div>
    );
  };
  

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);
  
  
  const projects = [
    { title: "Word of Bees", description: "Amplify Palestinian voices and debunk misinformation", icon: Globe, link: "https://wob-backend-qwtbrr3rxa-ew.a.run.app/auth/login" },
    { title: "Arabees Threads", description: "Archive Twitter threads for Palestine", icon: Archive, link: "https://threadbees.web.app/" },
    { title: "Arabees Polls", description: "Engage with polls and surveys", icon: BarChart, link: "https://arabees-polls.web.app/" },
    { title: "Arabees URLs", description: "Track and analyze link usage", icon: Link2, link: "https://arabees-urls.web.app" },
    { title: "Video Translator", description: "Translate and transcribe videos", icon: Video, link: "#video-translator" },
    { title: "OSINT Search", description: "Advanced search across multiple platforms", icon: Search, link: "#osint-search" },
    { title: "Data Visualizer", description: "Create interactive charts and graphs", icon: PieChart, link: "#data-visualizer" },
    { title: "Network Mapper", description: "Discover and visualize network topologies", icon: Wifi, link: "#network-mapper" },
    { title: "Vulnerability Scanner", description: "Identify security weaknesses in systems", icon: Shield, link: "#vulnerability-scanner" },
    { title: "Geolocation Tracker", description: "Track and analyze geographical data", icon: Map, link: "#geolocation-tracker" },
    { title: "Social Graph Analyzer", description: "Visualize and analyze social connections", icon: GitBranch, link: "#social-graph-analyzer" },
    { title: "AI-Powered Analysis", description: "Leverage AI for advanced OSINT analysis", icon: Cpu, link: "#ai-analysis" }
  ];

  return (
    <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
      <div className="flex h-screen bg-gray-100 dark:bg-[#0A1929]"> {/* Darker background */}
        {/* Sidebar */}
        <aside className={`${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} fixed inset-y-0 left-0 z-30 w-64 bg-white dark:bg-[#102B4E] transition duration-300 ease-in-out transform md:relative md:translate-x-0 shadow-lg`}>
          <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold text-primary dark:text-yellow-500">The Arabees</h2>
            <button onClick={() => setSidebarOpen(false)} className="md:hidden text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500">
              <X size={24} />
            </button>
          </div>
          <nav className="mt-4">
            {projects.map((project, index) => (
              <a key={index} href={project.link} className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-[#1D3A5F]">
                <project.icon size={20} className="mr-3" />
                {project.title}
              </a>
            ))}
          </nav>
        </aside>

        {/* Main content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Header */}
          <header className="bg-white dark:bg-[#102B4E] shadow-md">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-20"> {/* Increased height */}
                <button onClick={() => setSidebarOpen(!sidebarOpen)} className="md:hidden text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500">
                  <Menu size={24} />
                </button>
                <div className="flex-1 flex justify-center"> {/* Centered logo */}
                  <img 
                    src={darkMode ? logoDark : logoLight} 
                    alt="Logo" 
                    className="h-16 w-auto" /* Increased logo size */
                  />
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setDarkMode(!darkMode)}
                    className="p-2 rounded-md text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500 focus:outline-none"
                  >
                    {darkMode ? <Sun size={24} /> : <Moon size={24} />}
                  </button>
                  <button
                    onClick={() => setShowLogin(true)}
                    className="p-2 rounded-md text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-yellow-500 focus:outline-none"
                  >
                    <UserCircle size={24} />
                  </button>
                </div>
              </div>
            </div>
          </header>

          {/* Main content area */}
          <main className="flex-1 overflow-y-auto bg-gray-100 dark:bg-[#0A1929] p-4">
            <div className="max-w-7xl mx-auto">
              <h3 className="text-xl font-semibold text-primary dark:text-yellow-500 mt-6 mb-4">Dashboard</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {projects.map((project, index) => (
                  <DashboardCard
                    key={index}
                    {...project}
                    index={index}
                    onClick={() => setActiveModal(project.title)}
                  />
                ))}
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={activeModal !== null}
        onClose={() => setActiveModal(null)}
        title={activeModal}
      >
        <div className="text-gray-700 dark:text-gray-300">
          <p>{projects.find(project => project.title === activeModal)?.description}</p>
          <a
            href={projects.find(project => project.title === activeModal)?.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary dark:text-yellow-500 hover:underline mt-4 inline-block"
          >
            Go to project page
          </a>
        </div>
      </Modal>

      {/* Login Modal */}
      <Modal
        isOpen={showLogin}
        onClose={() => setShowLogin(false)}
        title="Login"
      >
        <Login onClose={() => setShowLogin(false)} darkMode={darkMode} />
      </Modal>
    </div>
  );
};

export default App;